import Field, {getFieldsForModel} from "~/models/decorators/Field";
import {computed, observable, set} from "mobx";
import {pick} from "lodash";

class PlusUser {

    avatarUrl: string;
    color: string;
    language: string;
    email: string;
    isViewer: boolean;
    lastActiveAt: string;
    isSuspended: boolean;
    deletedAt: string | undefined;
    isSaving: boolean;
    createdAt: string;
    updatedAt: string;
    store: any;

    id: number | undefined;
    companyId: number | undefined;
    companyInfoId: number | undefined;
    name: string | undefined;
    position: string | undefined;
    userId: number | undefined;
    departmentId: number | undefined;
    isAdmin: boolean | undefined;
    isDepManager: boolean | undefined;
    imageName: string | undefined;
    isContactManager: boolean | undefined;
    companyName: string | undefined;

    constructor(fields: Record<string, any>) {
        this.id = fields.id
        this.companyId = fields.companyId
        this.companyInfoId = fields.companyInfoId
        this.name = fields.name
        this.position = fields.position
        this.userId = fields.userId
        this.departmentId = fields.departmentId
        this.isAdmin = fields.isAdmin
        this.isDepManager = fields.isDepManager
        this.imageName = fields.imageName
        this.isContactManager = fields.isContactManager
        this.companyName = fields.companyName
    }


    save = async (
        params?: Record<string, any>,
        options?: Record<string, string | boolean | number | undefined>
    ) => {
        this.isSaving = true;

        try {
            // ensure that the id is passed if the document has one
            if (!params) {
                params = this.toAPI();
            }

            const model = await this.store.save({ ...params, id: this.id }, options);

            // if saving is successful set the new values on the model itself
            set(this, { ...params, ...model });

            this.persistedAttributes = this.toAPI();

            return model;
        } finally {
            this.isSaving = false;
        }
    };

    updateFromJson = (data: any) => {
        set(this, data);
        this.persistedAttributes = this.toAPI();
    };

    fetch = (options?: any) => {
        return this.store.fetch(this.id, options);
    };

    refresh = () => {
        return this.fetch({
            force: true,
        });
    };

    delete = async () => {
        this.isSaving = true;

        try {
            return await this.store.delete(this);
        } finally {
            this.isSaving = false;
        }
    };

    /**
     * Returns a plain object representation of fields on the model for
     * persistence to the server API
     *
     * @returns {Record<string, any>}
     */
    toAPI = (): Record<string, any> => {
        const fields = getFieldsForModel(this);
        return pick(this, fields) || [];
    };

    /**
     * Returns a plain object representation of all the properties on the model
     * overrides the inbuilt toJSON method to avoid attempting to serialize store
     *
     * @returns {Record<string, any>}
     */
    toJSON() {
        const output: Partial<typeof this> = {};

        for (const property in this) {
            if (
                // eslint-disable-next-line no-prototype-builtins
                this.hasOwnProperty(property) &&
                !["persistedAttributes", "store", "isSaving"].includes(property)
            ) {
                output[property] = this[property];
            }
        }

        return output;
    }

    /**
     * Returns a boolean indicating if the model has changed since it was last
     * persisted to the server
     *
     * @returns boolean true if unsaved
     */
    isDirty(): boolean {
        const attributes = this.toAPI();

        if (Object.keys(attributes).length === 0) {
            console.warn("Checking dirty on model with no @Field decorators");
        }

        return (
            JSON.stringify(this.persistedAttributes) !== JSON.stringify(attributes)
        );
    }

    /**
     * Returns a boolean indicating whether the model has been persisted to db
     *
     * @returns boolean true if the model has never been persisted
     */
    @computed
    get isNew(): boolean {
        return !this.id;
    }

    protected persistedAttributes: Partial<PlusUser> = {};

}

export default PlusUser;