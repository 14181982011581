import invariant from "invariant";
import { observable, action, computed, runInAction } from "mobx";
import { getCookie, setCookie, removeCookie } from "tiny-cookie";
import { getCookieDomain } from "@shared/utils/domains";
import RootStore from "~/stores/RootStore";
import PlusUser from "~/models/PlusUser";
import Policy from "~/models/Policy";
import Team from "~/models/Team";
import User from "~/models/User";
import { client } from "~/utils/ApiClient";

type Provider = {
  id: string;
  name: string;
  authUrl: string;
};

export type Config = {
  name?: string;
  hostname?: string;
  providers: Provider[];
};

// const AUTH_STORE = "AUTH_STORE";
const NO_REDIRECT_PATHS = ["/", "/create", "/home"];

const UserUUID = "2463fbe9-61ef-5212-9b43-yourUserId";

const TeamUUID = "2463fbe9-61ef-1829-9b43-yourCompanyId";

const mapErplusUserId2OutlineUserId = (
  id: number | string | null | undefined
) => {
  const str_id = id?.toString() || "";
  return UserUUID.replace("yourUserId", str_id.padStart(12, "0"));
};

const mapErplusTeamId2OutlineTeamId = (
  id: number | string | null | undefined
) => {
  const str_id = id?.toString() || "";
  return TeamUUID.replace("yourCompanyId", str_id.padStart(12, "0"));
};

const fetchCompany = (token: string | null) => {
  const url = "https://www.erplus.co/api/v1/company";
  return fetchPlus(token, url);
};

const fetchUser = (token: string | null) => {
  const url = "https://www.erplus.co/api/v1/profile";
  return fetchPlus(token, url);
};

const fetchPlus = (token: string | null, url: string) => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8;",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res;
      } else {
        throw new Error("Invalid token");
      }
    })
    .then((res) => {
      return res.json();
    });
};

export default class AuthStore {
  @observable
  user: User | null | undefined;

  @observable
  plusUser: PlusUser | null | undefined;

  @observable
  team: Team | null | undefined;

  @observable
  token: string | null | undefined;

  @observable
  login: boolean;

  @observable
  policies: Policy[] = [];

  @observable
  lastSignedIn: string | null | undefined;

  @observable
  isSaving = false;

  @observable
  isSuspended = false;

  @observable
  suspendedContactEmail: string | null | undefined;

  @observable
  config: Config | null | undefined;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const token = getCookie("accessToken");
    this.rehydrate(token);
  }

  @action
  fetchUser = async (token: string | null) => {
    const companyInfo = fetchCompany(token);
    const userInfo = fetchUser(token);
    const data = await Promise.all([companyInfo, userInfo]).then((result) => {
      const [companyRes, userRes] = result;

      const user = {
        id: mapErplusUserId2OutlineUserId(userRes.companyInfoId),
        name: userRes.name,
        avatarUrl: `https://image.erplus.co${userRes.imageName}`,
        color: "#9E5CF7",
        isAdmin: true,
        isSuspended: false,
        isViewer: false,
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        lastActiveAt: "2022-09-14T10:14:17.651Z",
        email: userRes.companyInfo.info.email,
        language: "zh_CN",
      };

      const team = {
        id: mapErplusTeamId2OutlineTeamId(companyRes.id),
        name: companyRes.name,
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        avatarUrl: "https://www.erplus.co/img-mp-icon_24.png",
        sharing: true,
        memberCollectionCreate: true,
        collaborativeEditing: true,
        defaultCollectionId: null,
        documentEmbeds: true,
        guestSignin: false,
        subdomain: "team-r8q8006",
        domain: null,
        url: "https://one.dualseason.com",
        defaultUserRole: "member",
        inviteRequired: false,
        allowedDomains: [],
      };
      const data = {
        user,
        team,
      };
      return data;
    });
    invariant(data, "Invalid token");
    return data;
  };

  @action
  fetch = async () => {
    try {
      const res = await client.post("/auth.info");
      invariant(res?.data, "Auth not available");
      runInAction("AuthStore#fetch", () => {
        this.addPolicies(res.policies);
        const { user, team } = res.data;
        this.user = new User(user, this);
        this.team = new Team(team, this);
        // Redirect to the correct custom domain or team subdomain if needed
        // Occurs when the (sub)domain is changed in admin and the user hits an old url
        const { hostname, pathname } = window.location;

        if (this.team.domain) {
          if (this.team.domain !== hostname) {
            window.location.href = `${team.url}${pathname}`;
            return;
          }
        }

        // If we came from a redirect then send the user immediately there
        // const postLoginRedirectPath = getCookie("postLoginRedirectPath");
        //
        // if (postLoginRedirectPath) {
        //   removeCookie("postLoginRedirectPath");
        //
        //   if (!NO_REDIRECT_PATHS.includes(postLoginRedirectPath)) {
        //     window.location.href = postLoginRedirectPath;
        //   }
        // }
      });
    } catch (err) {
      if (err.error === "user_suspended") {
        this.isSuspended = true;
        this.suspendedContactEmail = err.data.adminEmail;
      }
    }
  };

  @action
  rehydrate(token: string | null) {
    if (!token) {
      this.user = null;
      this.team = null;
      this.policies = [];
      this.token = null;
      this.login = false;
    } else {
      this.fetchUser(token)
        .then((res) => {
          this.setLoginFlag(true, token);
          this.plusUser = new PlusUser(res);
          const { user, team } = res;
          this.user = new User(user, this);
          this.team = new Team(team, this);
        })
        .catch(() => {
          this.user = null;
          this.team = null;
          this.policies = [];
          this.token = null;
          this.login = false;
        });
    }

    this.lastSignedIn = getCookie("lastSignedIn");
  }

  addPolicies(policies?: Policy[]) {
    if (policies) {
      // cache policies in this store so that they are persisted between sessions
      this.policies = policies;
      policies.forEach((policy) => this.rootStore.policies.add(policy));
    }
  }

  @action
  setLoginFlag(login: boolean, token: string | null | undefined) {
    this.token = token;
    if (token !== null) {
      setCookie("accessToken", token!);
    }
    this.login = login;
  }

  @action
  setPlusUser(res: { user: any; team: any }) {
    const { user, team } = res;
    this.user = new User(user, this);
    this.team = new Team(team, this);
  }

  @action
  setLogin(login: boolean) {
    console.log("login=>" + login);
    this.login = login;
  }

  @computed
  get authenticated(): boolean {
    return this.login;
  }

  @computed
  get asJson() {
    return {
      user: this.user,
      team: this.team,
      policies: this.policies,
    };
  }

  @action
  requestDelete = () => {
    return client.post(`/users.requestDelete`);
  };

  @action
  deleteUser = async (data: { code: string }) => {
    await client.post(`/users.delete`, data);
    runInAction("AuthStore#updateUser", () => {
      this.user = null;
      this.team = null;
      this.policies = [];
      this.token = null;
    });
  };

  @action
  updateUser = async (params: {
    name?: string;
    avatarUrl?: string | null;
    language?: string;
  }) => {
    this.isSaving = true;

    try {
      const res = await client.post(`/users.update`, params);
      invariant(res?.data, "User response not available");
      runInAction("AuthStore#updateUser", () => {
        this.addPolicies(res.policies);
        this.user = new User(res.data, this);
      });
    } finally {
      this.isSaving = false;
    }
  };

  @action
  updateTeam = async (params: {
    name?: string;
    avatarUrl?: string | null | undefined;
    sharing?: boolean;
    collaborativeEditing?: boolean;
    defaultCollectionId?: string | null;
    subdomain?: string | null | undefined;
    allowedDomains?: string[] | null | undefined;
  }) => {
    this.isSaving = true;

    try {
      const res = await client.post(`/team.update`, params);
      invariant(res?.data, "Team response not available");
      runInAction("AuthStore#updateTeam", () => {
        this.addPolicies(res.policies);
        this.team = new Team(res.data, this);
      });
    } finally {
      this.isSaving = false;
    }
  };

  @action
  logout = async (savePath = false) => {
    if (!this.token) {
      return;
    }

    client.post(`/auth.delete`);

    // if this logout was forced from an authenticated route then
    // save the current path so we can go back there once signed in
    if (savePath) {
      const pathName = window.location.pathname;

      if (!NO_REDIRECT_PATHS.includes(pathName)) {
        setCookie("postLoginRedirectPath", pathName);
      }
    }

    // remove authentication token itself
    removeCookie("accessToken", {
      path: "/",
    });
    // remove session record on apex cookie
    const team = this.team;

    if (team) {
      const sessions = JSON.parse(getCookie("sessions") || "{}");
      delete sessions[team.id];
      setCookie("sessions", JSON.stringify(sessions), {
        domain: getCookieDomain(window.location.hostname),
      });
    }

    // clear all credentials from cache (and local storage via autorun)
    this.user = null;
    this.team = null;
    this.policies = [];
    this.token = null;
  };
}
