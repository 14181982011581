import { filter, orderBy } from "lodash";
import { computed } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import User from "~/models/User";

export default class DocumentUserMembershipStore extends BaseStore<User> {
  constructor(rootStore: RootStore) {
    super(rootStore, User);
  }

  @computed
  get orderedData(): User[] {
    return orderBy(Array.from(this.data.values()), "name", "asc");
  }

  @computed
  get activeOrInvited(): User[] {
    return this.orderedData.filter((user) => !user.isSuspended);
  }

  inDocument = (documentId: string) => {
    const documentMembership = filter(
      this.rootStore.documentMemberships.orderedData,
      (member) => member.documentId === documentId
    );
    // 获取documentMembership里的所有userId;
    const userIds = documentMembership.map((member) => member.userId);
    return filter(this.activeOrInvited, (user) => userIds.includes(user.id));
  };

  notInDocument = (documentId: string) => {
    const documentMembership = filter(
      this.rootStore.documentMemberships.orderedData,
      (member) => member.documentId === documentId
    );
    // 获取documentMembership里的所有userId;
    const userIds = documentMembership.map((member) => member.userId);
    return filter(this.activeOrInvited, (user) => !userIds.includes(user.id));
  };
}
