import invariant from "invariant";
import { action, runInAction } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
// eslint-disable-next-line import/default
import DocumentMembership from "~/models/DocumentMembership";
import { client } from "~/utils/ApiClient";

type GetDocumentMembership = {
  id: string;
};

export default class DocumentMembershipStore extends BaseStore<
  DocumentMembership
> {
  constructor(rootStore: RootStore) {
    super(rootStore, DocumentMembership);
  }

  @action
  fetchPage = async (
    params: GetDocumentMembership
  ): Promise<DocumentMembership[]> => {
    this.isFetching = true;
    console.log("params", params);
    try {
      const res = await client.post("/documents.memberships", {
        id: params.id,
      });
      invariant(res?.data, "Data not avaliable");
      console.log("res", res);
      let models: DocumentMembership[] = [];
      runInAction(`DocumentMembershipStore$fetchPage`, () => {
        res.data.users.forEach(this.rootStore.documentUserMemberships.add);
        models = res.data.documentMemberships.map(this.add);
        this.isLoaded = true;
      });
      console.log(this.rootStore.documentUserMemberships.orderedData);
      console.log(this.rootStore.documentMemberships.orderedData);
      return models;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  async create({
    documentId,
    userId,
    permission,
  }: {
    documentId: string;
    userId: string;
    permission: "read_write" | "read" | null;
  }) {
    console.log("documentId", documentId);
    console.log("userId", userId);
    console.log("permission", permission);
    const res = await client.post("/documents.add_user", {
      id: documentId,
      userId,
      permission,
    });
    invariant(res?.data, "Membership data should be available");
    res.data.users.forEach(this.rootStore.documentUserMemberships.add);
    console.log("res", res.data.memberships);

    const memberships = res.data.memberships.map(this.add);
    return memberships[0];
  }

  @action
  async delete({ documentId, userId }: { documentId: string; userId: string }) {
    console.log("documentId", documentId);
    console.log("userId", userId);
    await client.post("/documents.remove_user", {
      id: documentId,
      userId,
    });
    this.remove(`${userId}-${documentId}`);
  }
}
