import { action, computed, observable } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import WujieList from "~/models/WujieList";
import { SelectedPeopleData } from "~/scenes/CollectionPermissions/components/WujieAddPeopleSingleSPA";

export default class WujieListStore extends BaseStore<WujieList> {
  @observable
  list: SelectedPeopleData[] | [];

  constructor(rootStore: RootStore) {
    super(rootStore, WujieList);
  }

  @computed
  get lists(): SelectedPeopleData[] | [] {
    return this.list;
  }

  @action
  setSelectPeopleList = (list: SelectedPeopleData[] | []) => {
    this.list = list;
  };
}
